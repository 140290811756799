<div class="common-header">
  <div class="common-header__logo">
    <img class="common-header__logo-image" src="assets/images/design/jobatory-logo.svg" alt=""
      routerLink="/dashboard" />
  </div>
  <ng-container *ngFor="let menuItem of mainMenu">
    <div class="btn-group" dropdown *ngIf="menuItem.show">
      <button dropdownToggle type="button" class="btn dropdown-toggle btn-header-menu"
        [class.btn-header-menu--active]="parentRoute == menuItem.route">
        <i class="display" *ngIf="parentRoute == menuItem.route">
          <img src="/assets/images/icons/{{ menuItem.icon }}-active.svg" />
        </i>
        <i *ngIf="!(parentRoute == menuItem.route)">
          <img class="icon-header-menu" src="/assets/images/icons/{{ menuItem.icon }}.svg" />
          <img class="display-on-hover" src="/assets/images/icons/{{ menuItem.icon }}-active.svg" />
        </i>
        {{ menuItem.name }} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <ng-container *ngFor="let subMenuItem of menuItem.subMenu">
          <li role="menuitem" *ngIf="subMenuItem.show">
            <a class="dropdown-item" routerLink="{{ subMenuItem.route }}" (click)="parentRoute = menuItem.route">
              <i>
                <img src="/assets/images/icons/submenuicom.svg" />
              </i>
              {{ subMenuItem.name }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
  <div class="common-header__profile">
    <ng-container>
      <div class="user-profile">
        <span dropdown>
          <a href id="basic-link" dropdownToggle (click)="(false)" aria-controls="basic-link-dropdown">
            <div class="user-profile__avatar" *ngIf="userProfile.image_url">
              <img [src]="userProfile?.image_url" alt="{{ userProfile?.first_name }}" />
            </div>
            <div class="client-avatar" *ngIf="!userProfile.image_url">
              <div class="m-auto">{{ userProfile.first_name | slice: 0:1 }}{{ userProfile.last_name | slice: 0:1 }}</div>

            </div>
          </a>

          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu user-profile-dropdown" role="menu"
            aria-labelledby="basic-link">
            <div class="user-profile__account">
              <div class="user-profile__image-wrapper" *ngIf="userProfile.image_url" >
                <img class="user-profile__image"
                  (click)="openUploadPhotoDialog(userProfile)"
                  [src]="userProfile?.image_url"
                  alt="{{ userProfile?.first_name }}"
                />
              </div>
              <div class="client-avatar-dropdown" *ngIf="!userProfile.image_url" (click)="openUploadPhotoDialog(userProfile)">
                <div class="m-auto">{{ userProfile.first_name | slice: 0:1 }}{{ userProfile.last_name | slice: 0:1 }}</div>
              </div>
              <div class="user-profile__info-wrapper">
                <div class="welcome-text text-fs-14">Welcome</div>
                <h4 class="name-text text-fs-18">
                  {{ userProfile?.first_name }} {{ userProfile?.last_name }}
                </h4>
                <!-- <a class="user-profile__my-account text-white text-fs-12"
                  [routerLink]="'/employees/update/' + userProfile?.id">My Account</a> -->
              </div>
            </div>
            <div class="user-profile__user-wrapper" [routerLink]="'/my-info'">
              <!-- <div class="user-profile__user-wrapper" [routerLink]="'/employees/update/' + userProfile?.id"> -->
              <div class="text-icon">
                <img width="24" height="24" src="/assets/images/icons/my-account-icon.svg" />
                <span class="user-profile__my-account text-fs-14">My Info</span>
              </div>
              <img width="24" height="24" class="my-account-logout" src="/assets/images/icons/arrow-right.svg" />
            </div>
            <div class="user-profile__logout-wrapper" (click)="onLogoutClick()">
              <img width="24" height="24" class="my-account-logout" src="/assets/images/icons/my-account-logout.svg" />
              <a class="user-profile__logout text-fs-14">Logout</a>
            </div>
          </ul>

        </span>
      </div>
      <div class="notification d-none d-sm-none d-md-none d-lg-block"></div>
    </ng-container>
    <div class="nav-toggler" [class.nav-toggler--opened]="sideMenuOpened" (click)="onShowSideMenu()"></div>
  </div>
</div>
