<div class="common-right-sidebar" [class.common-right-sidebar--show]="showSideMenu">
  <div class="common-right-sidebar__menu">
    <div class="common-right-sidebar__menu-item" *ngFor="let menuItem of mainMenu">
      <a
        routerLink="{{ menuItem.route }}"
        routerLinkActive="common-right-sidebar__menu-link--active"
        class="common-right-sidebar__menu-link"
        [class.common-right-sidebar__menu-link--disabled]="menuItem.hasSubMenu">
        <i class="display" *ngIf="parentRoute == menuItem.route">
          <img src="/assets/images/icons/{{ menuItem.icon }}-active.svg" />
        </i>
        <i *ngIf="!(parentRoute == menuItem.route)">
          <img class="icon-header-menu" src="/assets/images/icons/{{ menuItem.icon }}.svg" />
        </i>
        {{ menuItem.name }}
      </a>
      <div class="common-right-sidebar__sub-menu" *ngIf="menuItem.hasSubMenu">
        <ng-container *ngFor="let subMenuItem of menuItem.subMenu">
          <a
            
            routerLink="{{ subMenuItem.route }}"
            routerLinkActive="common-right-sidebar__sub-menu-item--active"
            class="common-right-sidebar__sub-menu-item"
          >
          <span style="margin-right: 12px;"><img src="/assets/images/icons/submenuicom.svg" width="22" height="22" style="margin-top: 2px;" /></span>  
          <span>{{ subMenuItem.name }}</span>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
