import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from '../../../../auth/shared/services/token-storage.service';
import { UploadPhotoDialogComponent } from '../../../../shared/components/upload-photo-dialog/upload-photo-dialog.component';
import { MainMenuModel } from '../../../../shared/models/navigation.model';
import { HeaderService } from '../../shared/service/header.service';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
  @Input() public userProfile: any;
  @Input() public mainMenu: MainMenuModel[];
  @Input() public notifications: any[] = [];

  @Output() public logoutClicked: EventEmitter<any> = new EventEmitter();
  @Output() public showSideMenu: EventEmitter<any> = new EventEmitter();

  public modalRef: BsModalRef;
  public sideMenuOpened = true;
  public parentRoute: string;

  constructor(
    private router: Router,
    private service: HeaderService,
    private spinner: NgxSpinnerService,
    private bsModalService: BsModalService,
    private toastr: ToastrService,
    private tokenStorageService: TokenStorageService
  ) { }

  public ngOnInit(): void {
    this.parentRoute = this.getParentRoute();
  }

  public onLogoutClick(): void {
    this.logoutClicked.emit();
  }

  public onShowSideMenu(): void {
    this.sideMenuOpened = !this.sideMenuOpened;
    this.showSideMenu.emit();
  }

  private getParentRoute(): string {
    let route = '';
    const foundedRoute = this.mainMenu.find(x => x.subMenu.find(y => y.route === this.router.url));
    if (foundedRoute) {
      route = foundedRoute.route;
    }

    return route;
  }

  openUploadPhotoDialog(userProfile) {
    const modalOptions: ModalOptions = {
      class: 'modal-md',
      initialState: {
        title: 'my Profile Photo',
        text: 'Upload profile photo',
        text1: 'Change',
        text2: 'Remove',
        imageUrl: userProfile.image_url,
        noImageUrl: '/assets/images/icons/user-avatar-photo.svg',
        buttonCancel: 'Cancel',
        buttonConfirm: 'Save',
        fileResponse: true,
      },

    };
    this.modalRef = this.bsModalService.show(
      UploadPhotoDialogComponent,
      modalOptions
    );

    this.modalRef.content.response.subscribe((avatarImage: any) => {
      if (avatarImage) {
        this.spinner.show();
        this.service.uploadAvatarImage(avatarImage, userProfile.id).subscribe(
          (data) => {
            const temp = this.tokenStorageService.getUser();
            this.toastr.success(
              'You have successfully updated avatar',
              'SUCCESS'
            );
            if (userProfile.id === temp.id) {
              temp.image_path = data.image_path;
              temp.image_url = data.image_url;
              this.tokenStorageService.saveUser(temp);
              this.tokenStorageService.avatarChange.next(temp);
            }
            this.spinner.hide();
            this.modalRef.hide();
          },
          () => {
            this.spinner.hide();
            this.toastr.error('Avatar can not updated', 'ERROR');
          }
        );
      } else {
        this.modalRef.hide();
      }
    });

    this.modalRef.content.responseDelete.subscribe((res: any) => {
      if (res) {
        this.spinner.show();
        this.service.deleteAvatarImage(userProfile.id).subscribe(
          (data) => {
            this.toastr.success(
              'You have successfully updated avatar',
              'SUCCESS'
            );
            this.spinner.hide();
            this.modalRef.hide();
          },
          () => {
            this.spinner.hide();
            this.toastr.error('Avatar can not updated', 'ERROR');
          }
        );
      }
    });
  }

}
