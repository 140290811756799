<div class="confirmation-dialog">
  <div class="confirmation-dialog-header flex space-between">
    <div class="title">
      {{title}}
    </div>
    <span class="close" (click)="cancel()"> <img src="/assets/images/icons/close-dialog.svg" /></span>
  </div>
  <div class="confirmation-dialog-text">
      <div *ngIf="avatarImage || imageUrl">
        <img class="photo-preview" [src]="avatarImage || imageUrl" alt="">
      </div>
    <div *ngIf="!avatarImage && !imageUrl">
      <img alt="" [src]="noImageUrl" />
    </div>
      <ng-container *ngIf="!imageUrl">
        <button class="text" (click)="uploadPhotoElement.click()">
          <span> <img src="/assets/images/icons/photo-icon.svg" />{{ text }}
          </span>
        </button>

        <div class="item-form-input" >
          <input #uploadPhotoElement (change)="setUserImage($event.target.files)" [style.display]="'none'" class="text-field" type="file" accept="image/*"
             />
        </div>
      </ng-container>
    <div class="text part-two" *ngIf="imageUrl">
      <ng-container>
        <button class="text1" (click)="uploadPhotoElement.click()">
          <span> <img src="/assets/images/icons/photo-icon.svg" />{{ text1 }}
          </span>
        </button>

        <div class="item-form-input">
          <input #uploadPhotoElement (change)="setUserImage($event.target.files)" [style.display]="'none'" class="text-field" type="file"
             />
        </div>
      </ng-container>
      <div class="text2" (click)="deletePhoto()">
        {{ text2 }}
      </div>

    </div>
  </div>
  <div class="confirmation-dialog-actions">
    <button class="button no" data-bs-dismiss="modal" (click)="cancel()">{{buttonCancel}}</button>
    <button class="button save" [disabled]="disabledSave" (click)="saveUserImage()">{{buttonConfirm}}</button>
  </div>
</div>
