import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { getHttpHeaders } from '../../../../shared/utils/http.utils';

const baseUrl = environment.API_URL;

@Injectable()
export class HeaderService {
  constructor(private http: HttpClient) {}

  public deleteAvatarImage(id: number): Observable<any> {
    return this.http.delete(`${baseUrl}/users/${id}/delete-image`, {
      headers: getHttpHeaders(),
    });
  }

  public uploadAvatarImage(file: File, id: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', file, file.name);

    return this.http.post(`${baseUrl}/users/${id}/upload-image`, formData);
  }
}
