import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MainMenuModel } from '../../../../shared/models/navigation.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-right-sidebar',
  templateUrl: './common-right-sidebar.component.html',
  styleUrls: ['./common-right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonRightSidebarComponent {
  @Input() public mainMenu: MainMenuModel[];
  @Input() public showSideMenu = true;

  public parentRoute: string;

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.parentRoute = this.getParentRoute();
  }

  private getParentRoute(): string {
    let route = '';
    const foundedRoute = this.mainMenu.find(x => x.subMenu.find(y => y.route === this.router.url));
    if (foundedRoute) {
      route = foundedRoute.route;
    }

    return route;
  }
}
