import { HttpHeaders } from '@angular/common/http';

export const getHttpHeaders = (): HttpHeaders =>
  new HttpHeaders({
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

export const fileToFormData = (file: File): FormData => {
  const formData = new FormData();
  formData.append('file', file, file.name);

  return formData;
};
