import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-upload-photo-dialog',
  templateUrl: './upload-photo-dialog.component.html',
  styleUrls: ['./upload-photo-dialog.component.scss']
})
export class UploadPhotoDialogComponent implements OnInit {
  @Input() title: string;
  @Input() public text: string;
  @Input() public text1: string;
  @Input() public text2: string;
  @Input() public imageUrl: string;
  @Input() public noImageUrl: string;
  @Input () public buttonCancel: string;
  @Input() public buttonConfirm: string;
  @Input() public fileResponse: boolean;
  @Output() response: EventEmitter<any> = new EventEmitter();
  @Output() responseDelete: EventEmitter<boolean> = new EventEmitter();

  public form: FormGroup;
  avatarImage: any = '';
  avatarFile: File;
  disabledSave: boolean;
  imageDeleted = false;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
      this.imageUrl ? this.disabledSave = false : this.disabledSave = true;
      this.form = this.formBuilder.group({
        photos: new FormControl(),
      });
  }

  public cancel() {
    this.response.emit(null);
  }

  public setUserImage(files: any): void {
    if (files.length > 0) {
      for (const element of files) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = (event) => {
          this.avatarImage = event.target.result;
          this.disabledSave = false;
          this.form.controls.photos.setValue(this.avatarImage);
        };
        this.avatarFile = element;
      }
    }
  }

  saveUserImage(){
    if(this.imageDeleted && this.imageUrl == null) {
      this.responseDelete.emit(true);
    } else if(this.fileResponse){
      this.response.emit(this.avatarFile);
    } else{
      this.response.emit(this.avatarImage);
    }
  }

  deletePhoto() {
    this.imageUrl = null;
    this.disabledSave = false;
    this.imageDeleted = true;
  }
}
